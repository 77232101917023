<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab>
        {{ $t('modulos.endpoints.abas.dados_endpoint') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.endpoints.abas.parametros') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <dados-endpoint
          ref="dados-endpoint"
          :form="form"
        />
      </v-tab-item>
      <v-tab-item>
        <parametros :form="form" />
      </v-tab-item>
    </v-tabs-items>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import EndpointsService from '@/common/services/cadastros/EndpointsService';
import { EndpointsModel } from '@/common/models/cadastros/EndpointsModel';
import Parametros from './components/Parametros.vue';
import DadosEndpoint from './components/DadosEndpoint.vue';

//import helpers from '@common/utils/helpers';
export default {
  components: {
    Parametros,
    DadosEndpoint,
  },
  props: ['id'],
  data() {
    return {
      indiceAba: 0,
      form: new EndpointsModel({}),
    };
  },
  computed: {
    tituloFormulario: function () {
      return this.id
        ? this.$t('modulos.endpoints.titulos.editar')
        : this.$t('modulos.endpoints.titulos.novo');
    },
  },
  watch: {},
  mounted: function () {
    if (this.id) {
      this.buscar(this.id);
    }

    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: async function (id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await EndpointsService.buscar(id)
        .then((res) => {
          this.form = new EndpointsModel(res.data);
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.endpoints.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if(!this.$refs['dados-endpoint'].$refs.form.validate()) return this.indiceAba = 0;
      this.confirmarSalvar().then(() => {
        this.salvar();
      });
    },
    salvar: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');

      await EndpointsService.salvar(this.form.request)
        .then(async () => {
          this.$router.push({ name: 'endpoints' });
          this.toastSucesso(this.$t(`modulos.endpoints.cadastro_sucesso`));
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    voltar() {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'endpoints' });
      });
    },
    cancelar: function () {
      this.voltar();
    },
  },
};
</script>
