<template>
  <div>
    <modal-padrao
      ref="modal-parametros"
      max-width="75%"
      :titulo="$t('modulos.endpoints.formulario.endpointsParametros.novo_parametro')"
      :titulo-ok="$t('geral.botoes.salvar')"
      :titulo-cancelar="$t('geral.botoes.voltar')"
      :ok-desabilitado="!valido"
      @ok="salvar"
    >
      <v-form
        ref="form"
        class="row mt-4"
      >
        <input-text
          v-model="form.nome"
          class="col-12 col-md-3"
          :label="$t('modulos.endpoints.formulario.endpointsParametros.nome')"
          obrigatorio
          trim
          :max="150"
        />

        <input-select
          v-model="selecionarIdTipoParametro"
          class="col-12 col-md-6"
          :label="$t('modulos.endpoints.formulario.endpointsParametros.tipoParametro')"
          :placeholder="$t('modulos.endpoints.formulario.endpointsParametros.tipoParametro')"
          obrigatorio
          :options="seletorTipoParametro"
          retornar-objeto
        />
        <input-select
          v-model="selecionarIdTipoPassagemParametro"
          class="col-12 col-md-6"
          :label="$t('modulos.endpoints.formulario.endpointsParametros.tipoPassagemParametro')"
          :placeholder="$t('modulos.endpoints.formulario.endpointsParametros.tipoPassagemParametro')"
          obrigatorio
          :options="seletorTipoPassagemParametro"
          retornar-objeto
        />

        <input-text
          v-model="form.simbolo"
          class="col-12 col-md-4"
          :label="$t('modulos.endpoints.formulario.endpointsParametros.simbolo')"
          obrigatorio
          :max="150"
        />
        <input-radio
          v-model="form.obrigatorio"
          class="col-12 col-md-2"
          :divider="false"
          :options="opcoes.booleano"
          :label="$t('modulos.campo_adicional.formulario.campoObrigatorio')"
        />
      </v-form>
    </modal-padrao>
  </div>
</template>

<script>
//import EndpointsService from '@/common/services/cadastros/EndpointsService';
import EnumeradorService from '@/common/services/cadastros/EnumeradorService';
import InputText from '@components/inputs/InputText.vue';
import InputRadio from '@components/inputs/InputRadio.vue';
import InputSelect from '@components/inputs/InputSelect.vue';
import helpers from '@common/utils/helpers';
export default {
  components: {InputText, InputRadio, InputSelect  },
  data() {
    return {
      form: {},
      input: false,
      valido: false,
      opcoes: {
        booleano: helpers.BoleanoEnum,
      },
      seletorTipoParametro: [],
      seletorTipoPassagemParametro: [],
    };
  },
  computed:{
    selecionarIdTipoParametro:{
      get(){
        return this.form.tipoParametro;
      },
      set(valor){
        this.form.tipoParametro = valor;
      }
    },
    selecionarIdTipoPassagemParametro:{
      get(){
        return this.form.tipoPassagemParametro;
      },
      set(valor){
        this.form.tipoPassagemParametro = valor;
      }
    }
  },
  watch: {
  form: {
    handler() {
      if (this.$refs['form']) {
        this.valido = this.$refs.form.validate()
      }
    },
    deep: true,
  },
},
mounted() {
      this.listarRegistros();
    },

 methods: {
    resetarValidacao(){
      this.$refs['form'].resetValidation();
    },

    abrirModal: function () {
      //this.iniciarForm();
      this.input = true;

      this.$refs['modal-parametros'].abrirModal();
      setTimeout(() => {
        this.resetarValidacao();
      }, 100);
    },
    listarRegistros: function () {

      this.$store.dispatch('Layout/iniciarCarregamento');

      EnumeradorService.buscar('EnumTipoParametro')
        .then((res) => {
         res.forEach((r)=>{

           this.seletorTipoParametro.push(r.value)
         })
        })
      EnumeradorService.buscar('EnumTipoPassagemParametro')
      .then((res) => {
        res.forEach(r => {
          this.seletorTipoPassagemParametro.push(r.value);

        });
      })

        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    salvar() {
      this.$emit('novoParametro', this.form);
      this.input = false;

      this.$refs['modal-parametros'].fecharModal();
    },
  },
};
</script>
