<template>
  <div>
    <v-form
      ref="form"
      class="row mt-4"
    >
      <input-text
        v-model="form.nome"
        class="col-12 col-md-3"
        :label="$t('modulos.endpoints.formulario.nome')"
        obrigatorio
        trim
        :max="150"
      />
      <input-text
        v-model="form.url"
        class="col-12 col-md-3"
        :label="$t('modulos.endpoints.formulario.url')"
        :obrigatorio="form.api != 'Sql'"
        :disabled="form.api == 'Sql'"
      />
      <input-select
        v-model="form.moduloSistema"
        class="col-12 col-md-6"
        :label="$t('modulos.endpoints.formulario.moduloSistema')"
        :placeholder="$t('modulos.endpoints.formulario.moduloSistema')"
        obrigatorio
        :options="seletorTipoModulo"
      />

      <input-select
        v-model="form.api"
        class="col-12 col-md-3"
        :label="$t('modulos.endpoints.formulario.api')"
        :placeholder="$t('modulos.endpoints.formulario.api')"
        obrigatorio
        :options="seletorTipoAPI"
      />
      <input-sql
        v-model="form.nomeArquivoSql"
        class="col-12 col-md-3"
        :label="$t('modulos.endpoints.formulario.nome_arquivo')"
        :multiplos="false"
        :obrigatorio="form.api == 'Sql'"
        :disabled="form.api != 'Sql'"
      />
      <input-text
        v-model="form.jsonModelo"
        class="col-12 col-md-6"
        :label="$t('modulos.endpoints.formulario.jsonModelo')"
        :obrigatorio="form.api != 'Sql'"
        :disabled="form.api == 'Sql'"
      />
      <div
        v-if="form.api != 'Sql'"
        class="col-12 col-md-12"
      >
        <span>JSON Preview</span>
        <v-card class="v-card--flat px-0 mx-0">
          <vue-json-pretty
            :show-icon="true"
            :data="jsonPreview"
            :deep="0"
          />
        </v-card>
      </div>
    </v-form>
  </div>
</template>
<script>

import {
  InputSql
} from '@components/inputs';
import InputText from '@components/inputs/InputText.vue';
import InputSelect from '@components/inputs/InputSelect.vue';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import helpers from '@/common/utils/helpers';
export default {
  components: { InputText, InputSelect, VueJsonPretty, InputSql },
  props: {
    form: {},
  },

  data() {
    return {
      valido: false,
      jsonObrigatorio: false,
      seletorTipoModulo: helpers.ModuloEnum,
      seletorTipoAPI: helpers.ApiEnum,
    };
  },
  computed: {
    jsonPreview(){
      let object;
      try {
          object = JSON.parse(this.form.jsonModelo);
        } catch {
          object = {}
        }
      return object;
    },
  },
  watch: {
    'form.jsonModelo': {
      handler(value) {
        let jsonValido = true;
        try {
          JSON.parse(value);
        } catch (e) {
          jsonValido = false;
        }
        if (jsonValido) {
          this.jsonObrigatorio = true;
        } else {
          this.jsonObrigatorio = true;
          this.valido = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    validacaoForm: function () {
      this.$refs.form.validate();
      this.$emit('validacao-endpoint', this.$refs.form.validate());
    },
  },
};
</script>
