import _ from 'lodash';

export class EndpointsModel {
  constructor({
    id,
    url,
    nome,
    jsonModelo = '{}',
    moduloSistema,
    api,
    endpointsParametros,
    nomeArquivoSql = '',
  }) {
    this.url = url;
    this.id = id;
    this.nome = nome;
    this.jsonModelo = jsonModelo;
    this.verboHttp = 'GET';
    this.moduloSistema = moduloSistema;
    this.api = api;
    this.endpointsParametros = endpointsParametros ?? [];
    this.nomeArquivoSql = nomeArquivoSql && {text: nomeArquivoSql};
  }
  get request() {
    let retorno = _.cloneDeep(this);
    retorno.nomeArquivoSql = retorno.nomeArquivoSql?.text;
    
    if (retorno.api == 'Sql') {
      retorno.jsonModelo = '';
      retorno.url = '';
    } else {
      retorno.nomeArquivoSql = '';
    }

    retorno.endpointsParametros = retorno.endpointsParametros?.map((e) => {
      return {
        nome: e.nome,
        simbolo: e.simbolo,
        tipoParametro: e.tipoParametro,
        obrigatorio: e.obrigatorio,
        tipoPassagemParametro: e.tipoPassagemParametro,
      };
    });

    return retorno;
  }
}
