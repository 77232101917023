<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <div>
        <botao-padrao
          v-if="podeExcluir"
          class="my-2"
          outlined
          color="danger"
          @click="excluirRegistros"
        >
          <v-icon>$mdiTrashCanOutline</v-icon>
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div>
      <botao-padrao class="mt-3" @click="abreModalParametros">
        {{
          $t('modulos.endpoints.formulario.endpointsParametros.novo_parametro')
        }}
      </botao-padrao>
    </div>
    <tabela-padrao
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      sem-paginacao
    />

    <parametros-modal
      ref="modal-parametros"
      :form="form"
      @novoParametro="novoParametro"
    />
  </div>
</template>

<script>
import ParametrosModal from './modais/ParametrosModal.vue';

export default {
  components: { ParametrosModal },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    apenasVisualizar: { type: Boolean, default: false },
  },
  data() {
    return {
      //endpointParametros:[],
      tabela: {
        selecionados: [],
        dados: this.form.endpointsParametros,
        colunas: [
          /* {
            value: 'acoes',
            text: '',
          }, */
          {
            value: 'nome',
            text: this.$t(
              'modulos.endpoints.formulario.endpointsParametros.nome'
            ),
            sortable: true,
          },
          {
            value: 'tipoParametro',
            text: this.$t(
              'modulos.endpoints.formulario.endpointsParametros.tipoParametro'
            ),
            sortable: true,
          },
          {
            value: 'tipoPassagemParametro',
            text: this.$t(
              'modulos.endpoints.formulario.endpointsParametros.tipoPassagemParametro'
            ),
            sortable: true,
          },
        ],
      },
    };
  },
  computed: {
    podeExcluir() {
      return this.tabela.selecionados != 0;
    },
  },
  watch: {
    endpointsParametros: function () {
      this.tabela.dados = this.form.endpointsParametros;
    },
    deep: true,
  },

  mounted() {},
  methods: {
    abreModalParametros() {
      this.$refs['modal-parametros'].abrirModal();
    },
    novoParametro(parametros) {
      this.form.endpointsParametros.push(parametros);
    },

    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.form.endpointsParametros.splice(
            this.form.endpointsParametros.indexOf(element),
            1
          );
        });
        this.tabela.selecionados = [];
      });
    },
    validacaoForm: function () {
      this.$refs.form.validate();
      this.$emit('validacao-endpoint', this.$refs.form.validate());
    },
  },
};
</script>
